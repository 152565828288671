const getUnitResidents = `
        query getUnitResidents($communityId: ID!, $currentOnly: Boolean, $first: Int, $after: String){
          unitResidents(communityId: $communityId, currentOnly: $currentOnly, first: $first, after: $after) {
            edges {
              node {
                id
                lastAccessedAt
                unit {
                    id
                    internalIdentifier
                }
                resident {
                  id
                  firstName
                  lastName
                  fullName
                  profileImageUrl
                  email
                  createdAt
                }
                createdAt
              }
            }
            totalCount
            pageInfo {
              startCursor
              endCursor
            }
          }
        }
    `;

const departUnitResident = `
        mutation DepartUnitResident($id: ID!) {
          departUnitResident(id: $id) {
            id
            unit {
              id
            }
            resident {
              id
              firstName
              lastName
              fullName
              email
              profileImageUrl
            }
            departedAt
            createdAt
          }
        }
    `;

export default {
    getUnitResidents,
    departUnitResident
}