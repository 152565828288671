const createDataImport = `
        mutation CreateDataImport($communityId: ID!, $importType: String!, $dataImportFileBlobId: String!) {
          createDataImport(communityId: $communityId, importType: $importType, dataImportFileBlobId: $dataImportFileBlobId) {
            id
            dataImportFileName
            dataImportFileUrl
            recordsProcessed
            recordsChanged
            createdAt
          }
        }
    `;

export default {
    createDataImport
};