import React, { createContext, useReducer, useMemo } from 'react';
import PropTypes from 'prop-types';

const initialState = [];

const initialContext = [ ...initialState , () => []];

export const SettingsContext = createContext(initialContext);

const reducer = (state, action) => {
    switch (action.type) {
        case "set":
            return action.payload;
        case "reset":
            return initialState;
        default:
            return;
    }
};

export function SettingsController(props) {
    const [settingsState, updateSettingsState] = useReducer(reducer, initialState);
    const value = useMemo(() => [settingsState, updateSettingsState], [settingsState]);

    return (<SettingsContext.Provider value={value}>
        {props.children}
    </SettingsContext.Provider>);
}

SettingsController.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired
};