const getPosts = `
        query GetPosts($reader: ReaderInput!, $searchTerm: String, $unread: Boolean, $unreadWithPinned: Boolean, $createdAfter: ISO8601DateTime, $after: String, $before: String, $first: Int, $last: Int){
          posts(reader: $reader, searchTerm: $searchTerm, unread: $unread, unreadWithPinned: $unreadWithPinned, createdAfter: $createdAfter, after: $after, before: $before, first: $first, last: $last) {
            edges {
              node {
                id
                postable {
                  ... on UnitResident {
                    __typename
                    id
                    unit {
                      id
                      internalIdentifier
                    }
                    resident {
                      id
                      firstName
                      lastName
                      email
                      profileImageUrl
                    }
                  }
                  ... on CommunityManager {
                    __typename
                    id
                    manager {
                      id
                      firstName
                      lastName
                      email
                      profileImageUrl
                    }
                  }
                }
                receivable {
                  ... on Unit {
                    __typename
                    id
                    internalIdentifier
                  }
                  ... on UnitResident {
                    __typename
                    id
                    unit {
                      id
                      internalIdentifier
                    }
                    resident {
                      id
                      firstName
                      lastName
                      email
                      profileImageUrl
                    }
                  }
                  ... on Community {
                    __typename
                    id
                    name
                  }
                }
                unread
                unreadReplies
                title
                body
                hidePoster
                pinned
                pinExpiresAt
                createdAt
              }
            }
            totalCount
            pageInfo {
              startCursor
              endCursor
            }
          }
        }
    `;

const getPost = `
        query GetPost($id: ID!){
          post(id: $id) {
            id
            postable {
              ... on UnitResident {
                __typename
                id
                unit {
                  id
                  internalIdentifier
                }
                resident {
                  id
                  firstName
                  lastName
                  email
                  profileImageUrl
                }
              }
              ... on CommunityManager {
                __typename
                id
                manager {
                  id
                  firstName
                  lastName
                  email
                  profileImageUrl
                }
              }
            }
            receivable {
              ... on Unit {
                __typename
                id
                internalIdentifier
              }
              ... on UnitResident {
                __typename
                id
                unit {
                  id
                  internalIdentifier
                }
                resident {
                  id
                  firstName
                  lastName
                  email
                  profileImageUrl
                }
              }
              ... on Community {
                __typename
                id
                name
              }
            }
            title
            body
            hidePoster
            pinExpiresAt
            postReplies {
              id
              replyable {
                ... on UnitResident {
                  __typename
                  id
                  unit {
                    id
                    internalIdentifier
                  }
                  resident {
                    id
                    firstName
                    lastName
                    email
                    profileImageUrl
                  }
                }
                ... on CommunityManager {
                  __typename
                  id
                  manager {
                    id
                    firstName
                    lastName
                    email
                    profileImageUrl
                  }
                }
              }
              hidePoster
              body
              createdAt
            }
            postFlags {
              id
              flagger {
                ... on UnitResident {
                  __typename
                  id
                  resident {
                    id
                  }
                }
                ... on CommunityManager {
                  __typename
                  id
                  manager {
                    id
                  }
                }
              }
              createdAt
            }
            createdAt
          }
        }
    `;

const readPost = `
        mutation ReadPost($id: ID!, $readerType: String!, $readerId: ID!) {
          readPost(id: $id, readerType: $readerType, readerId: $readerId) {
            id
          }
        }
    `;

const createPost = `
        mutation CreatePost($communityId: ID!, $title: String!, $body: String!, $postableType: String!, $postableId: ID!, $receivableType: String, $receivableId: ID, $hidePoster: Boolean, $pinExpiresAt: ISO8601DateTime) {
          createPost(communityId: $communityId, title: $title, body: $body, postableType: $postableType, postableId: $postableId, receivableType: $receivableType, receivableId: $receivableId, hidePoster: $hidePoster, pinExpiresAt: $pinExpiresAt) {
            id
            receivable {
              ... on Unit {
                __typename
                id
                internalIdentifier
              }
              ... on UnitResident {
                __typename
                id
                resident {
                  id
                  firstName
                  lastName
                  email
                  profileImageUrl
                }
              }
              ... on Community {
                __typename
                id
                name
              }
            }
            title
            body
            createdAt
          }
        }
    `;

export default {
    getPosts,
    getPost,
    readPost,
    createPost
};