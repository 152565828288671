const getFeeRevenues = `
        query getFeeRevenues($communityId: ID!, $unitId: ID, $status: String, $startDate: ISO8601DateTime, $endDate: ISO8601DateTime){
          feeRevenues(communityId: $communityId, unitId: $unitId, status: $status, startDate: $startDate, endDate: $endDate) {
            id
            name
            priceCents
            totalRevenueAmountCents
            quantity
            percentageOfTotalRevenue
          }
        }
    `;

const getInvoiceRevenues = `
        query getInvoiceRevenues($communityId: ID!, $unitId: ID, $status: String, $startDate: ISO8601DateTime, $endDate: ISO8601DateTime, $first: Int, $after: String){
          invoiceRevenues(communityId: $communityId, unitId: $unitId, status: $status, startDate: $startDate, endDate: $endDate, first: $first, after: $after) {
            edges {
              node {
                id
                invoiceLineItems {
                  description
                  quantity
                  amountCents
                }
                unitResident {
                  unit {
                    id
                    internalIdentifier
                  }
                  resident {
                    fullName
                  }
                }
                dueDate
                pastDue
                pastDueDays
                paid
                paidAt
                status
                totalCents
                amountRefundedCents
                createdAt
                refunds {
                    amountCents
                    status
                    stripeCreated
                }
              }
            }
            totalCount
            grandTotalCents
            pageInfo {
              startCursor
              endCursor
            }
          }
        }
    `;

const getUnitRevenues = `
        query getUnitRevenues($communityId: ID!, $unitId: ID, $status: String, $startDate: ISO8601DateTime, $endDate: ISO8601DateTime, $first: Int, $after: String){
          unitRevenues(communityId: $communityId, unitId: $unitId, status: $status, startDate: $startDate, endDate: $endDate, first: $first, after: $after) {
            edges {
              node {
                id
                internalIdentifier
                totalRevenueAmountCents
                invoiceCount
              }
            }
            totalCount
            totalInvoiceCount
            grandTotalCents
            pageInfo {
              startCursor
              endCursor
            }
          }
        }
    `;

export default {
    getFeeRevenues,
    getInvoiceRevenues,
    getUnitRevenues
}