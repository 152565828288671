const getStripeInvoice = `
        query getStripeInvoice($stripeInvoiceId: String!){
          stripeInvoice(stripeInvoiceId: $stripeInvoiceId) {
            id
            description
            dueAt
            hostedInvoiceUrl
            paid
            paidAt
            status
            subscription
            total
            createdAt
            charge {
              id
              amount
              refunded
              amountRefunded
              status
              failureMessage
              paid
              createdAt
              refunds {
                id
                amount
                status
                createdAt
              }
            }
          }
        }
    `;

const getInvoice = `
        query getInvoice($id: ID!){
          invoice(id: $id) {
            id
            stripeInvoiceId
            dueDate
            pastDue
            paid
            paidAt
            status
            recurring
            totalCents
            stripeCreated
            charges {
              id
              stripeChargeId
              amountCents
              refunded
              amountRefundedCents
              status
              failureMessage
              paid
              stripeCreated
              refunds {
                id
                amountCents
                status
                stripeCreated
              }
            }
          }
        }
    `;

const getStripeUpcomingInvoice = `
        query getStripeUpcomingInvoice($customer: String, $subscription: String){
          stripeUpcomingInvoice(customer: $customer, subscription: $subscription) {
            description
            dueAt
            status
            customer
            subscription
            total
            createdAt
          }
        }
    `;

const getStripeInvoices = `
        query getStripeInvoices($customer: String, $status: String!, $subscription: String){
          stripeInvoices(customer: $customer, status: $status, subscription: $subscription) {
            id
            description
            dueAt
            hostedInvoiceUrl
            collectionMethod
            paid
            paidAt
            status
            subscription
            total
            createdAt
            charge {
              id
              amount
              refunded
              amountRefunded
              status
              failureMessage
              paid
              createdAt
              refunds {
                id
                amount
                status
                createdAt
              }
            }
          }
        }
    `;

const getInvoices = `
        query getInvoices($stripeCustomerId: String, $status: String!, $stripeSubscriptionId: String, $unitId: ID, $first: Int, $after: String){
          invoices(stripeCustomerId: $stripeCustomerId, status: $status, stripeSubscriptionId: $stripeSubscriptionId, unitId: $unitId, first: $first, after: $after) {
            edges {
              node {
                id
                stripeInvoiceId
                unitResident {
                  resident {
                    firstName
                    lastName
                  }
                }
                dueDate
                pastDue
                pastDueDays
                collectionMethod
                paid
                paidAt
                status
                pendingCharges
                recurring
                totalCents
                estimatedOutOfBandServiceFeeAmountCents
                estimatedCardServiceFeeAmountCents
                estimatedBankAccountServiceFeeAmountCents
                stripeCreated
                charges {
                  id
                  stripeChargeId
                  amountCents
                  refunded
                  amountRefundedCents
                  status
                  failureMessage
                  paid
                  stripeCreated
                  refunds {
                    id
                    amountCents
                    status
                    stripeCreated
                  }
                }
              }
            }
            totalCount
            pageInfo {
              startCursor
              endCursor
            }
          }
        }
    `;

const payInvoice = `
        mutation PayInvoice($stripeInvoiceId: String!, $stripeSourceId: String, $stripePaymentMethodId: String){
          payInvoice(stripeInvoiceId: $stripeInvoiceId, stripeSourceId: $stripeSourceId, stripePaymentMethodId: $stripePaymentMethodId) {
            id
            description
            dueAt
            hostedInvoiceUrl
            collectionMethod
            paid
            paidAt
            status
            subscription
            total
            createdAt
          }
        }
    `;

const markInvoicePaid = `
        mutation MarkInvoicePaid($stripeInvoiceId: String!){
          markInvoicePaid(stripeInvoiceId: $stripeInvoiceId) {
            id
            description
            dueAt
            hostedInvoiceUrl
            collectionMethod
            paid
            paidAt
            status
            subscription
            total
            createdAt
          }
        }
    `;

const createRefund = `
        mutation CreateRefund($stripeChargeId: String!, $amount: Int){
          createRefund(stripeChargeId: $stripeChargeId, amount: $amount) {
            id
            amount
            status
            createdAt
          }
        }
    `;

const createInvoice = `
        mutation CreateInvoice($stripeCustomerId: String!, $stripePriceId: String!){
          createInvoice(stripeCustomerId: $stripeCustomerId, stripePriceId: $stripePriceId) {
            id
          }
        }
    `;

export default {
    getStripeInvoice,
    getInvoice,
    getStripeUpcomingInvoice,
    getStripeInvoices,
    getInvoices,
    payInvoice,
    markInvoicePaid,
    createRefund,
    createInvoice
}