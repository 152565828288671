const createCommunity = `
        mutation CreateCommunity($name: String!, $website: String, $contactEmail: String!, $contactPhoneNumber: String!, $addressStreet1: String!, $addressStreet2: String, $addressCity: String!, $addressState: String!, $addressZip: String!, $establishedYear: Int, $twitterHandle: String, $facebookHandle: String, $instagramHandle: String, $timeZoneId: ID!, $communityStatedUnitCount: Int!) {
          createCommunity(name: $name, website: $website, contactEmail: $contactEmail, contactPhoneNumber: $contactPhoneNumber, address: { street1: $addressStreet1, street2: $addressStreet2, city: $addressCity, state: $addressState, zip: $addressZip }, establishedYear: $establishedYear, twitterHandle: $twitterHandle, facebookHandle: $facebookHandle, instagramHandle: $instagramHandle, timeZoneId: $timeZoneId, communityStatedUnitCount: $communityStatedUnitCount) {
            id
            name
            website
            contactEmail
            contactPhoneNumber
            address {
              id
              street1
              street2
              city
              state
              zip
              latitude
              longitude
              fullAddress
              createdAt
            }
            createdBy {
              id
              firstName
              lastName
              fullName
              profileImageUrl
              email
              createdAt
            }
            hoaDuesFees {
              id
              classification
              name
              priceCents
              recurring
            }
            token
            profileImageUrl
            establishedYear
            twitterHandle
            facebookHandle
            instagramHandle
            status
            communityStatedUnitCount
            invoiceNetDays
            maskPostCreatorByDefault
            currentUserCommunityManager {
              id
              manager {
                id
                firstName
                lastName
                email
              }
            }
            payoutAccounts {
                id
                name
                stripeAccountId
            }
            timeZone {
              id
              name
              label
            }
            createdAt
          }
        }
    `;

const updateCommunity = `
        mutation UpdateCommunity($id: ID!, $name: String!, $website: String, $contactEmail: String!, $contactPhoneNumber: String!, $addressStreet1: String!, $addressStreet2: String, $addressCity: String!, $addressState: String!, $addressZip: String!, $establishedYear: Int, $twitterHandle: String, $facebookHandle: String, $instagramHandle: String, $timeZoneId: ID!, $communityStatedUnitCount: Int!) {
          updateCommunity(id: $id, name: $name, website: $website, contactEmail: $contactEmail, contactPhoneNumber: $contactPhoneNumber, address: { street1: $addressStreet1, street2: $addressStreet2, city: $addressCity, state: $addressState, zip: $addressZip }, establishedYear: $establishedYear, twitterHandle: $twitterHandle, facebookHandle: $facebookHandle, instagramHandle: $instagramHandle, timeZoneId: $timeZoneId, communityStatedUnitCount: $communityStatedUnitCount) {
            id
            name
            website
            contactEmail
            contactPhoneNumber
            address {
              id
              street1
              street2
              city
              state
              zip
              latitude
              longitude
              fullAddress
              createdAt
            }
            createdBy {
              id
              firstName
              lastName
              fullName
              profileImageUrl
              email
              createdAt
            }
            hoaDuesFees {
              id
              classification
              name
              priceCents
              recurring
            }
            token
            profileImageUrl
            establishedYear
            twitterHandle
            facebookHandle
            instagramHandle
            status
            communityStatedUnitCount
            invoiceNetDays
            maskPostCreatorByDefault
            currentUserCommunityManager {
              id
              manager {
                id
                firstName
                lastName
                email
              }
            }
            payoutAccounts {
                id
                name
                stripeAccountId
            }
            timeZone {
              id
              name
              label
            }
            createdAt
          }
        }
    `;

const updateCommunitySettings = `
        mutation UpdateCommunitySettings($id: ID!, $invoiceNetDays: Int, $maskPostCreatorByDefault: Boolean) {
          updateCommunitySettings(id: $id, invoiceNetDays: $invoiceNetDays, maskPostCreatorByDefault: $maskPostCreatorByDefault) {
            id
            invoiceNetDays
            maskPostCreatorByDefault
            createdAt
          }
        }
    `;

const getCommunity = `
        query getCommunity($id: ID!) {
          community(id: $id) {
            id
            name
            website
            contactEmail
            contactPhoneNumber
            address {
              id
              street1
              street2
              city
              state
              zip
              latitude
              longitude
              fullAddress
              createdAt
            }
            createdBy {
              id
              firstName
              lastName
              fullName
              profileImageUrl
              email
              createdAt
            }
            hoaDuesFees {
              id
              classification
              name
              priceCents
              recurring
            }
            token
            profileImageUrl
            establishedYear
            twitterHandle
            facebookHandle
            instagramHandle
            status
            communityStatedUnitCount
            unitSubscriptionsSynchronizing
            invoiceNetDays
            maskPostCreatorByDefault
            currentUserCommunityManager {
              id
              manager {
                id
                firstName
                lastName
                email
              }
            }
            payoutAccounts {
                id
                name
                stripeAccountId
            }
            timeZone {
              id
              name
              label
            }
            createdAt
          }
        }
    `;

const getCommunityWithAccessUpdate = `
        query getCommunityWithAccessUpdate($id: ID!) {
          communityWithAccessUpdate(id: $id) {
            id
            name
            website
            contactEmail
            contactPhoneNumber
            address {
              id
              street1
              street2
              city
              state
              zip
              latitude
              longitude
              fullAddress
              createdAt
            }
            createdBy {
              id
              firstName
              lastName
              fullName
              profileImageUrl
              email
              createdAt
            }
            hoaDuesFees {
              id
              classification
              name
              priceCents
              recurring
            }
            token
            profileImageUrl
            establishedYear
            twitterHandle
            facebookHandle
            instagramHandle
            status
            communityStatedUnitCount
            invoiceNetDays
            maskPostCreatorByDefault
            currentUserCommunityManager {
              id
              manager {
                id
                firstName
                lastName
                email
              }
            }
            timeZone {
              id
              name
              label
            }
            createdAt
          }
        }
    `;

const getCommunityManagers = `
        query getCommunityManagers($id: ID!){
          community(id: $id) {
            id
            communityManagers {
                id
                lastAccessedAt
                manager {
                  id
                  firstName
                  lastName
                  fullName
                  profileImageUrl
                  email
                  createdAt
                }
                createdAt
            }
          }
        }
    `;

const destroyCommunityManager = `
        mutation DestroyCommunityManager($id: ID!) {
          destroyCommunityManager(id: $id) {
            id
          }
        }
    `;

const activateCommunity = `
        mutation ActivateCommunity($id: ID!) {
          activateCommunity(id: $id) {
            id
          }
        }
    `;

const getCommunityAllPendingInvites = `
        query GetCommunityAllPendingInvites($id: ID!) {
          community(id: $id) {
            id
            allPendingInvites {
              id
              email
              sender {
                id
                email
              }
              recipient {
                id
                email
              }
              invitable {
                ... on Community {
                  id
                  name
                }
                ... on Unit {
                  id
                  internalIdentifier
                }
              }
              token
              sendCount
              createdAt
            }
          }
        }
    `;

const attachProfileImage = `
        mutation AttachCommunityProfileImage($communityId: ID!, $profileImageBlobId: String!) {
          attachCommunityProfileImage(communityId: $communityId, profileImageBlobId: $profileImageBlobId) {
            id
          }
        }
    `;

const getCommunityUnitSubscriptionSyncStatus = `
        query GetCommunityUnitSubscriptionSyncStatus($id: ID!){
          community(id: $id) {
            id
            unitSubscriptionsSynchronizing
          }
        }`;

const getCommunityStats = `
        query GetCommunityStats($id: ID!){
          community(id: $id) {
            id
            openUnitResidentInvoicesCount
            currentResidentsCount
            vacantUnitsCount
            unitsPendingCloseCount
          }
        }`;

const getCommunityStatistics = `
        query GetCommunityStatistics($id: ID!){
          community(id: $id) {
            id
            unitsCount
            openInvoicesCount
            openInvoicesTotalCents
            pastDueInvoicesCount
            pastDueInvoicesTotalCents
            unitsWithOpenInvoicesCount
            unitsWithPastDueInvoicesCount
            monthToDateRevenueCents
            monthToDateBilledCents
          }
        }`;

const getCommunityPayoutAccounts = `
        query GetCommunityPayoutAccounts($id: ID!){
          community(id: $id) {
            id
            payoutAccounts {
                id
                name
                status
                stripeAccountId
                externalAccountLast4
                feesCount
                createdAt
            }
          }
        }`;

const getCommunityVerifiedPayoutAccounts = `
        query GetCommunityVerifiedPayoutAccounts($id: ID!){
          community(id: $id) {
            id
            verifiedPayoutAccounts {
                id
                name
                status
                stripeAccountId
                externalAccountLast4
                createdAt
            }
          }
        }`;

export default {
    createCommunity,
    updateCommunity,
    updateCommunitySettings,
    getCommunity,
    getCommunityWithAccessUpdate,
    getCommunityManagers,
    destroyCommunityManager,
    activateCommunity,
    getCommunityAllPendingInvites,
    attachProfileImage,
    getCommunityUnitSubscriptionSyncStatus,
    getCommunityStats,
    getCommunityStatistics,
    getCommunityPayoutAccounts,
    getCommunityVerifiedPayoutAccounts
};