const getStripePaymentMethods = `
        query GetStripePaymentMethods($stripeCustomerId: String!) {
          stripePaymentMethods(stripeCustomerId: $stripeCustomerId) {
            id
            type
            card {
                brand
                last4
                expMonth
                expYear
            }
            bankAccount {
                accountHolderType
                accountType
                bankName
                last4
                routingNumber
            }
          }
        }
    `;

const attachPaymentMethod = `
        mutation AttachPaymentMethod($stripeCustomerId: String!, $stripePaymentMethodId: String!, $setAsCustomerDefault: Boolean) {
            attachPaymentMethod(stripeCustomerId: $stripeCustomerId, stripePaymentMethodId: $stripePaymentMethodId, setAsCustomerDefault: $setAsCustomerDefault) {
              id
              card {
                brand
                last4
              }
            }
        }
    `;

const detachPaymentMethod = `
        mutation DetachPaymentMethod($stripePaymentMethodId: String!) {
            detachPaymentMethod(stripePaymentMethodId: $stripePaymentMethodId) {
              id
              card {
                brand
                last4
              }
            }
        }
    `;

const setDefaultPaymentMethod = `
        mutation SetDefaultPaymentMethod($stripeCustomerId: String!, $stripePaymentMethodId: String!) {
            setDefaultPaymentMethod(stripeCustomerId: $stripeCustomerId, stripePaymentMethodId: $stripePaymentMethodId) {
              id
            }
        }
    `;

export default {
    getStripePaymentMethods,
    attachPaymentMethod,
    detachPaymentMethod,
    setDefaultPaymentMethod
};