import React, { createContext, useReducer, useMemo } from 'react';
import PropTypes from 'prop-types';

const initialState = {
    user: {}
};

const initialContext = [{ ...initialState }, () => {}];

export const UserContext = createContext(initialContext);

const updater = (state, update) => {
    return { ...state, ...update };
};

export function UserController(props) {
    const [userState, updateUser] = useReducer(updater, initialState);
    const value = useMemo(() => [userState, updateUser], [userState]);

    return (<UserContext.Provider value={value}>
        {props.children}
    </UserContext.Provider>);
}

UserController.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired
};