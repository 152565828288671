const Colors = {
    brandPrimary: '#CD423B',
    brandSecondary: '#E0998F',
    brandTertiary: '#F3EED9',
    brandQuaternary: '#798897',
    monoDarkGrey: '#141414',
    monoMedGrey: '#434343',
    monoMedGreySemiTransparent: '#43434399',
    monoMedLightGrey: '#ADADAD',
    monoLightGrey: '#D5D5D5',
    monoBrightGrey: '#E8E8E8',
    monoBright: '#FFFFFF',
    mainYellow: '#FFE83F',
    mainBlue: '#49A1FF',
    mainGreen: '#95DE64',
    mainGreenDark: '#7AB252',
    mainOrange: '#F99A2C',
    paymentsYellow: '#FFDD61',
    mainPurple: '#AE89ED',
    dangerRed: '#FF4D4F',
    stripeBrandColor: '#645BFF'
}

export default Colors;