const getTasks = `
        query GetTasks($communityId: ID!, $searchTerm: String, $assigneeId: ID, $active: Boolean, $inactive: Boolean, $after: String, $before: String, $first: Int, $last: Int){
          tasks(communityId: $communityId, searchTerm: $searchTerm, assigneeId: $assigneeId, active: $active, inactive: $inactive, after: $after, before: $before, first: $first, last: $last) {
            edges {
              node {
                id
                title
                description
                category
                unit {
                  id
                  internalIdentifier
                }
                assignee {
                  id
                  fullName
                  profileImageUrl
                }
                priority
                status
                active
                createdBy {
                  id
                  fullName
                }
                dueAt
                pastDue
                createdAt
                updatedAt
              }
            }
            totalCount
            pageInfo {
              startCursor
              endCursor
            }
          }
        }
    `;

const getMyTasksForCount = `
        query getMyTasksForCount($communityId: ID!, $assigneeId: ID, $active: Boolean, $after: String, $before: String, $first: Int, $last: Int){
          tasks(communityId: $communityId, assigneeId: $assigneeId, active: $active, after: $after, before: $before, first: $first, last: $last) {
            edges {
              node {
                id
              }
            }
            totalCount
            pageInfo {
              startCursor
              endCursor
            }
          }
        }
    `;

const getTask = `
        query GetTask($id: ID!){
          task(id: $id) {
            id
            title
            description
            category
            unit {
              id
              internalIdentifier
            }
            assignee {
              id
              fullName
            }
            priority
            status
            active
            createdBy {
              id
              fullName
            }
            dueAt
            pastDue
            attachments {
              id
              filename
              contentType
              url
            }
            createdAt
            updatedAt
          }
        }
    `;

const createTask = `
        mutation CreateTask($communityId: ID!, $title: String!, $description: String, $category: TaskCategory!, $unitId: ID, $assigneeId: ID, $priority: TaskPriority!, $status: TaskStatus!, $dueAt: ISO8601DateTime) {
          createTask(communityId: $communityId, title: $title, description: $description, category: $category, unitId: $unitId, assigneeId: $assigneeId, priority: $priority, status: $status, dueAt: $dueAt) {
            id
            title
            description
            category
            unit {
              id
              internalIdentifier
            }
            assignee {
              id
              fullName
            }
            priority
            status
            active
            createdBy {
              id
              fullName
            }
            dueAt
            pastDue
            attachments {
              id
              filename
              contentType
              url
            }
            createdAt
            updatedAt
          }
        }
    `;

const updateTask = `
        mutation UpdateTask($id: ID!, $title: String!, $description: String, $category: TaskCategory!, $unitId: ID, $assigneeId: ID, $priority: TaskPriority!, $status: TaskStatus!, $dueAt: ISO8601DateTime) {
          updateTask(id: $id, title: $title, description: $description, category: $category, unitId: $unitId, assigneeId: $assigneeId, priority: $priority, status: $status, dueAt: $dueAt) {
            id
            title
            description
            category
            unit {
              id
              internalIdentifier
            }
            assignee {
              id
              fullName
            }
            priority
            status
            active
            createdBy {
              id
              fullName
            }
            dueAt
            pastDue
            attachments {
              id
              filename
              contentType
              url
            }
            createdAt
            updatedAt
          }
        }
    `;

const uploadTaskAttachments = `
        mutation UploadTaskAttachments($taskId: ID!, $attachments: [Upload!]!) {
          uploadTaskAttachments(taskId: $taskId, attachments: $attachments) {
            id
            title
            description
            category
            unit {
              id
              internalIdentifier
            }
            assignee {
              id
              fullName
            }
            priority
            status
            active
            createdBy {
              id
              fullName
            }
            dueAt
            pastDue
            attachments {
              id
              filename
              contentType
              url
            }
            createdAt
            updatedAt
          }
        }
      `;

const removeTaskAttachment = `
        mutation RemoveTaskAttachment($taskId: ID!, $attachmentId: ID!) {
          removeTaskAttachment(taskId: $taskId, attachmentId: $attachmentId) {
            id
            title
            description
            category
            unit {
              id
              internalIdentifier
            }
            assignee {
              id
              fullName
            }
            priority
            status
            active
            createdBy {
              id
              fullName
            }
            dueAt
            pastDue
            attachments {
              id
              filename
              contentType
              url
            }
            createdAt
            updatedAt
          }
        }
      `;

const destroyTask = `
        mutation DestroyTask($id: ID!) {
          destroyTask(id: $id) {
            id
          }
        }
    `;

export default {
    getTasks,
    getMyTasksForCount,
    getTask,
    createTask,
    updateTask,
    uploadTaskAttachments,
    removeTaskAttachment,
    destroyTask
};