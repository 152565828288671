const createSubscriptions = `
        mutation CreateSubscriptions($interval: String!, $unitResidentId: ID!) {
          createSubscriptions(interval: $interval, unitResidentId: $unitResidentId) {
            id
            status
            stripeSubscriptionId
            subscribable {
              ... on Unit {
                id
              }
            }
            subscriber {
                resident {
                  id
                  firstName
                  lastName
                  email
                }
              }
            interval
            autopayAt
            canceledAt
            createdAt
            updatedAt
          }
        }
    `;

const updateSubscriptions = `
        mutation UpdateSubscriptions($interval: String!, $unitResidentId: ID!, $autopay: Boolean!) {
          updateSubscriptions(interval: $interval, unitResidentId: $unitResidentId, autopay: $autopay) {
            id
            status
            stripeSubscriptionId
            subscribable {
              ... on Unit {
                id
              }
            }
            subscriber {
                resident {
                  id
                  firstName
                  lastName
                  email
                }
              }
            interval
            autopayAt
            canceledAt
            createdAt
            updatedAt
          }
        }
    `;

export default {
    createSubscriptions,
    updateSubscriptions
}