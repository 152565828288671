const createDirectoryListing = `
        mutation CreateDirectoryListing(
            $communityId: ID!,
            $name: String!,
            $category: String!,
            $phoneNumber: String,
            $email: String,
            $website: String) {
          createDirectoryListing(
            communityId: $communityId,
            name: $name,
            category: $category,
            phoneNumber: $phoneNumber,
            email: $email,
            website: $website
          ) {
            id
            community {
              id
              name
            }
            name
            category
            phoneNumber
            email
            website
            createdAt
            updatedAt
          }
        }
    `;

const updateDirectoryListing = `
        mutation UpdateDirectoryListing(
            $id: ID!, 
            $name: String!,
            $category: String!,
            $phoneNumber: String,
            $email: String,
            $website: String
        ) {
          updateDirectoryListing(
            id: $id,
            name: $name,
            category: $category,
            phoneNumber: $phoneNumber,
            email: $email,
            website: $website
        ) {
            id
            community {
              id
              name
            }
            name
            category
            phoneNumber
            email
            website
            createdAt
            updatedAt
          }
        }
    `;

const destroyDirectoryListing = `
        mutation DestroyDirectoryListing($id: ID!) {
          destroyDirectoryListing(id: $id) {
            id
          }
        }
    `;

const getDirectoryListings = `
        query getDirectoryListings($communityId: ID!, $first: Int, $after: String){
          directoryListings(communityId: $communityId, first: $first, after: $after) {
            edges {
              node {
                id
                name
                category
                phoneNumber
                email
                website
                createdAt
                updatedAt
              }
            }
            totalCount
            pageInfo {
              startCursor
              endCursor
            }
          }
        }
    `;

export default {
    createDirectoryListing,
    updateDirectoryListing,
    destroyDirectoryListing,
    getDirectoryListings
}