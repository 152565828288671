const getResources = `
        query GetResources($communityId: ID, $residentViewable: Boolean, $community: Boolean, $resourceableId: ID, $createdAfter: ISO8601DateTime, $searchTerm: String, $after: String, $before: String, $first: Int, $last: Int){
          resources(communityId: $communityId, residentViewable: $residentViewable, community: $community, resourceableId: $resourceableId, createdAfter: $createdAfter, searchTerm: $searchTerm, after: $after, before: $before, first: $first, last: $last) {
            edges {
              node {
                id
                createdBy {
                  id
                  firstName
                  lastName
                  fullName
                  email
                  profileImageUrl
                }
                resourceable {
                  ... on Unit {
                    __typename
                    id
                    internalIdentifier
                  }
                }
                name
                residentViewable
                description
                displayOnTimeline
                resourceFileName
                resourceFileUrl
                createdAt
              }
            }
            totalCount
            pageInfo {
              startCursor
              endCursor
            }
          }
        }
    `;

const getResourcesForResident = `
        query GetResources($communityId: ID, $resourceableNilOrId: ID, $createdAfter: ISO8601DateTime, $searchTerm: String, $after: String, $before: String, $first: Int, $last: Int){
          resources(communityId: $communityId, residentViewable: true, resourceableNilOrId: $resourceableNilOrId, createdAfter: $createdAfter, searchTerm: $searchTerm, after: $after, before: $before, first: $first, last: $last) {
            edges {
              node {
                id
                createdBy {
                  id
                  firstName
                  lastName
                  fullName
                  email
                  profileImageUrl
                }
                resourceable {
                  ... on Unit {
                    __typename
                    id
                    internalIdentifier
                  }
                }
                name
                residentViewable
                description
                displayOnTimeline
                resourceFileName
                resourceFileUrl
                createdAt
              }
            }
            totalCount
            pageInfo {
              startCursor
              endCursor
            }
          }
        }
    `;

const getResource = `
        query GetResource($id: ID!){
          resource(id: $id) {
            id
            createdBy {
              id
              firstName
              lastName
              fullName
              email
              profileImageUrl
            }
            resourceable {
              ... on Unit {
                __typename
                id
                internalIdentifier
              }
            }
            name
            residentViewable
            description
            displayOnTimeline
            resourceFileName
            resourceFileUrl
            createdAt
          }
        }
    `;

const createResource = `
        mutation CreateResource($communityId: ID!, $name: String, $residentViewable: Boolean, $description: String, $displayOnTimeline: Boolean, $resourceableType: String, $resourceableId: ID, $resourceFileBlobId: String) {
          createResource(communityId: $communityId, name: $name, residentViewable: $residentViewable, description: $description, displayOnTimeline: $displayOnTimeline, resourceableType: $resourceableType, resourceableId: $resourceableId, resourceFileBlobId: $resourceFileBlobId) {
            id
            createdBy {
              id
              firstName
              lastName
              fullName
              email
              profileImageUrl
            }
            resourceable {
              ... on Unit {
                __typename
                id
                internalIdentifier
              }
            }
            name
            residentViewable
            description
            displayOnTimeline
            resourceFileName
            resourceFileUrl
            createdAt
          }
        }
    `;

const updateResource = `
        mutation UpdateResource($id: ID!, $name: String, $residentViewable: Boolean, $description: String, $displayOnTimeline: Boolean, $resourceableType: String, $resourceableId: ID) {
          updateResource(id: $id, name: $name, residentViewable: $residentViewable, description: $description, displayOnTimeline: $displayOnTimeline, resourceableType: $resourceableType, resourceableId: $resourceableId) {
            id
            createdBy {
              id
              firstName
              lastName
              fullName
              email
              profileImageUrl
            }
            resourceable {
              ... on Unit {
                __typename
                id
                internalIdentifier
              }
            }
            name
            residentViewable
            description
            displayOnTimeline
            resourceFileName
            resourceFileUrl
            createdAt
          }
        }
    `;

const destroyResource = `
        mutation DestroyResource($id: ID!) {
          destroyResource(id: $id) {
            id
          }
        }
    `;

export default {
    getResources,
    getResourcesForResident,
    getResource,
    createResource,
    updateResource,
    destroyResource
};