const getSettings = `
        query {
          settings {
            var
            value
          }
        }
    `;

export default {
    getSettings
};