import { FileChecksum } from "@rails/activestorage/src/file_checksum";

const calculateChecksum = file => {
    return new Promise((resolve, reject) => {
        FileChecksum.create(file, (error, checksum) => {
            if (error) {
                reject(error);
                return;
            }

            resolve(checksum);
        });
    });
}

export default {
    calculateChecksum
}