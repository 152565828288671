const termsOfUseURL = 'https://www.dwellpass.com/terms-of-use';
const privacyPolicyURL = 'https://www.dwellpass.com/privacy-policy';
const residentMobileGooglePlayStoreURL = "https://play.google.com/store/apps/details?id=com.nuance_innovation.dwellpass_client";
const residentMobileAppStoreURL = "https://apps.apple.com/us/app/dwellpass/id6444082166";

export default {
    termsOfUseURL,
    privacyPolicyURL,
    residentMobileGooglePlayStoreURL,
    residentMobileAppStoreURL
};