const getStripeCustomerWithSources = `
        query GetStripeCustomerWithSources($stripeCustomerId: String!) {
          stripeCustomer(stripeCustomerId: $stripeCustomerId) {
            id
            name
            email
            defaultSource
            sources {
              id
              accountHolderName
              accountHolderType
              bankName
              country
              last4
              status
            }
            invoiceSettings {
                defaultPaymentMethod
            }
            createdAt
          }
        }
    `;

const createSource = `
        mutation CreateSource($stripeCustomerId: String!, $stripeSourceToken: String!) {
            createSource(stripeCustomerId: $stripeCustomerId, stripeSourceToken: $stripeSourceToken) {
              id
              accountHolderName
              accountHolderType
              bankName
              country
              last4
              status
            }
        }
    `;

const verifySource = `
        mutation VerifySource($stripeCustomerId: String!, $stripeSourceId: String!, $depositAmount1: Int!, $depositAmount2: Int!) {
            verifySource(stripeCustomerId: $stripeCustomerId, stripeSourceId: $stripeSourceId, depositAmount1: $depositAmount1, depositAmount2: $depositAmount2) {
              id
              accountHolderName
              accountHolderType
              bankName
              country
              last4
              status
            }
        }
    `;

const setSourceDefault = `
        mutation SetSourceDefault($stripeCustomerId: String!, $stripeSourceId: String!) {
            setSourceDefault(stripeCustomerId: $stripeCustomerId, stripeSourceId: $stripeSourceId) {
              id
            }
        }
    `;

const deleteSource = `
        mutation DeleteSource($stripeCustomerId: String!, $stripeSourceId: String!) {
            deleteSource(stripeCustomerId: $stripeCustomerId, stripeSourceId: $stripeSourceId) {
              id
              deleted
            }
        }
    `;

export default {
    getStripeCustomerWithSources,
    createSource,
    verifySource,
    setSourceDefault,
    deleteSource
};