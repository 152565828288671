const createFee = `
        mutation CreateFee(
            $classification: String!,
            $name: String!,
            $priceCents: Int!,
            $communityId: ID!,
            $payoutAccountId: ID!,
            $eligibleForResidentAddOnAt: ISO8601DateTime,
            $unitDefault: Boolean,
            $recurring: String) {
          createFee(
            classification: $classification,
            name: $name,
            priceCents: $priceCents,
            communityId: $communityId,
            payoutAccountId: $payoutAccountId,
            eligibleForResidentAddOnAt: $eligibleForResidentAddOnAt,
            unitDefault: $unitDefault,
            recurring: $recurring
          ) {
            id
            classification
            name
            eligibleForResidentAddOnAt
            priceCents
            recurring
            createdAt
            updatedAt
          }
        }
    `;

const updateFee = `
        mutation UpdateFee(
            $id: ID!, 
            $classification: String!,
            $name: String!,
            $eligibleForResidentAddOnAt: ISO8601DateTime,
            $unitDefault: Boolean
        ) {
          updateFee(
            id: $id,
            classification: $classification,
            name: $name,
            eligibleForResidentAddOnAt: $eligibleForResidentAddOnAt,
            unitDefault: $unitDefault
        ) {
            id
            classification
            name
            eligibleForResidentAddOnAt
            priceCents
            recurring
            createdAt
            updatedAt
          }
        }
    `;

const destroyFee = `
        mutation DestroyFee($id: ID!) {
          destroyFee(id: $id) {
            id
          }
        }
    `;

const addFeeToAllUnits = `
        mutation AddFeeToAllUnits($id: ID!) {
          addFeeToAllUnits(id: $id) {
            id
          }
        }
    `;

const getFees = `
        query getFees($communityId: ID!, $recurringOnly: Boolean, $first: Int, $after: String){
          fees(communityId: $communityId, recurringOnly: $recurringOnly, first: $first, after: $after) {
            edges {
              node {
                id
                classification
                name
                priceCents
                stripePriceId
                eligibleForResidentAddOnAt
                recurring
                unitDefault
                createdAt
                unitFeeCount
                existsOnAllUnits
                payoutAccount {
                    id
                    name
                    externalAccountLast4
                }
              }
            }
            totalCount
            pageInfo {
              startCursor
              endCursor
            }
          }
        }
    `;

export default {
    createFee,
    updateFee,
    destroyFee,
    addFeeToAllUnits,
    getFees
}