const createPayoutAccount = `
        mutation CreatePayoutAccount($name: String!, $communityId: ID!) {
          createPayoutAccount(name: $name, communityId: $communityId) {
            id
          }
        }
    `;

const updatePayoutAccount = `
        mutation UpdatePayoutAccount($id: ID!, $name: String!) {
          updatePayoutAccount(id: $id, name: $name) {
            id
          }
        }
    `;

const destroyPayoutAccount = `
        mutation DestroyPayoutAccount($id: ID!) {
          destroyPayoutAccount(id: $id) {
            id
          }
        }
    `;

const getStripeAccountLink = `
        query GetStripeAccountLink($payoutAccountId: ID!){
          stripeAccountLink(payoutAccountId: $payoutAccountId) {
            createdAt
            expiresAt
            url
          }
        }`;

const getStripeAccountLoginLink = `
        query GetStripeAccountLoginLink($payoutAccountId: ID!){
          stripeAccountLoginLink(payoutAccountId: $payoutAccountId) {
            createdAt
            url
          }
        }`;

export default {
    createPayoutAccount,
    updatePayoutAccount,
    destroyPayoutAccount,
    getStripeAccountLink,
    getStripeAccountLoginLink
}