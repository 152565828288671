const getTimeZones = `
        query {
          timeZones {
            id
            name
            label
          }
        }
    `;

export default {
    getTimeZones
};