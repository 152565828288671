const createRevenueExport = `
        mutation CreateRevenueExport($communityManagerId: ID!, $options: JSON, $exportType: String!) {
          createRevenueExport(communityManagerId: $communityManagerId, options: $options, exportType: $exportType) {
            id
            options
            createdAt
          }
        }
    `;

export default {
    createRevenueExport
};