const createUnitFee = `
        mutation CreateUnitFee($unitId: ID!, $feeId: ID!) {
          createUnitFee(unitId: $unitId, feeId: $feeId) {
            id
          }
        }
    `;

const destroyUnitFee = `
        mutation DestroyUnitFee($id: ID!) {
          destroyUnitFee(id: $id) {
            id
          }
        }
    `;

export default {
    createUnitFee,
    destroyUnitFee
}