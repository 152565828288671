const getFeatureFlags = `
        query {
          featureFlags {
            name
            enabled
          }
        }
    `;

export default {
    getFeatureFlags
};