const getMe = `
        query {
          me {
            id
            firstName
            lastName
            fullName
            email
            createdAt
            updatedAt
            profileImageUrl
            managedCommunities {
              id
              name
              status
              profileImageUrl
              createdAt
            }
            currentUnits {
              id
              internalIdentifier
              address {
                street1
              }
            }
            lastAccessedManagedCommunity {
              id
              name
              status
              createdAt
            }
            lastAccessedUnit {
              id
              internalIdentifier
            }
            lastAccessedSegment
            pushNotificationDevices {
              id
              platform
              deviceToken
              createdAt
            }
          }
        }
    `;

const updateMe = `
        mutation UpdateMe($firstName: String!, $lastName: String!) {
          updateMe(firstName: $firstName, lastName: $lastName) {
            id
            firstName
            lastName
            fullName
            email
            createdAt
            updatedAt
            profileImageUrl
            managedCommunities {
              id
              name
              status
              profileImageUrl
              createdAt
            }
            currentUnits {
              id
              internalIdentifier
              address {
                street1
              }
            }
            lastAccessedManagedCommunity {
              id
              name
              status
              createdAt
            }
            lastAccessedUnit {
                id
                internalIdentifier
            }
            lastAccessedSegment
            pushNotificationDevices {
              id
              platform
              deviceToken
              createdAt
            }
          }
        }
    `;

const attachProfileImage = `
        mutation AttachProfileImage($profileImageBlobId: String!) {
          attachProfileImage(profileImageBlobId: $profileImageBlobId) {
            id
            firstName
            lastName
            fullName
            email
            createdAt
            updatedAt
            profileImageUrl
            managedCommunities {
              id
              name
              status
              profileImageUrl
              createdAt
            }
            currentUnits {
              id
              internalIdentifier
            }
            lastAccessedManagedCommunity {
              id
              name
              status
              createdAt
            }
            lastAccessedUnit {
                id
                internalIdentifier
            }
            lastAccessedSegment
          }
        }
    `;

const changeMyPassword = `
        mutation ChangeMyPassword($password: String!, $passwordConfirmation: String!) {
          changeMyPassword(password: $password, passwordConfirmation: $passwordConfirmation) {
            id
          }
        }
    `;

const createPushNotificationDevice = `
        mutation CreatePushNotificationDevice($platform: String!, $deviceToken: String!) {
          createPushNotificationDevice(platform: $platform, deviceToken: $deviceToken) {
            id
          }
        }
    `;

const updatePushNotificationDevice = `
        mutation UpdatePushNotificationDevice($deviceToken: String!, $lastReceivedAt: ISO8601DateTime!) {
          updatePushNotificationDevice(deviceToken: $deviceToken, lastReceivedAt: $lastReceivedAt) {
            id
          }
        }
    `;

const destroyPushNotificationDevice = `
        mutation DestroyPushNotificationDevice($deviceToken: String!) {
          destroyPushNotificationDevice(deviceToken: $deviceToken) {
            id
          }
        }
    `;

export default {
    getMe,
    updateMe,
    attachProfileImage,
    changeMyPassword,
    createPushNotificationDevice,
    updatePushNotificationDevice,
    destroyPushNotificationDevice
};