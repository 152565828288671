const createDirectUpload = `
        mutation CreateDirectUpload($filename: String!, $contentType: String!, $checksum: String!, $byteSize: Int!){
           createDirectUpload(input: { filename: $filename, contentType: $contentType, checksum: $checksum, byteSize: $byteSize}) {
             directUpload {
              blobId
              headers
              signedBlobId
              url
             }
           }
        }
    `;

export default {
    createDirectUpload
};