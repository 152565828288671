import React, { createContext, useReducer, useMemo } from 'react';
import PropTypes from 'prop-types';

const initialState = {};

const initialContext = [{ ...initialState }, () => {}];

export const UnitContext = createContext(initialContext);

const reducer = (state, action) => {
    switch (action.type) {
        case "set":
            return action.payload;
        case "reset":
            return initialState;
        default:
            return;
    }
};

export function UnitController(props) {
    const [unitState, updateUnitState] = useReducer(reducer, initialState);
    const value = useMemo(() => [unitState, updateUnitState], [unitState]);

    return (<UnitContext.Provider value={value}>
        {props.children}
    </UnitContext.Provider>);
}

UnitController.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired
};