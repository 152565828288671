import React, { createContext, useReducer, useMemo } from 'react';
import PropTypes from 'prop-types';

const initialState = [];

const initialContext = [ ...initialState , () => []];

export const FeatureFlagsContext = createContext(initialContext);

const reducer = (state, action) => {
    switch (action.type) {
        case "set":
            return action.payload;
        case "reset":
            return initialState;
        default:
            return;
    }
};

export function FeatureFlagsController(props) {
    const [featureFlagsState, updateFeatureFlagsState] = useReducer(reducer, initialState);
    const value = useMemo(() => [featureFlagsState, updateFeatureFlagsState], [featureFlagsState]);

    return (<FeatureFlagsContext.Provider value={value}>
        {props.children}
    </FeatureFlagsContext.Provider>);
}

FeatureFlagsController.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired
};