const createUnit = `
        mutation CreateUnit($internalIdentifier: String!, $communityId: ID!, $addressStreet1: String!, $addressStreet2: String, $addressCity: String!, $addressState: String!, $addressZip: String!, $tagList: [String!]) {
          createUnit(internalIdentifier: $internalIdentifier, communityId: $communityId, address: { street1: $addressStreet1, street2: $addressStreet2, city: $addressCity, state: $addressState, zip: $addressZip }, tagList: $tagList) {
            id
            internalIdentifier
            community {
                id
                name
            }
            address {
              id
              street1
              street2
              city
              state
              zip
              latitude
              longitude
              fullAddress
              createdAt
            }
            tagList
            latestClosingAt
            createdAt
            updatedAt
          }
        }
    `;

const updateUnit = `
        mutation UpdateUnit($id: ID!, $internalIdentifier: String!, $addressStreet1: String!, $addressStreet2: String, $addressCity: String!, $addressState: String!, $addressZip: String!, $tagList: [String!]) {
          updateUnit(id: $id, internalIdentifier: $internalIdentifier, address: { street1: $addressStreet1, street2: $addressStreet2, city: $addressCity, state: $addressState, zip: $addressZip }, tagList: $tagList) {
            id
            internalIdentifier
            community {
                id
                name
            }
            address {
              id
              street1
              street2
              city
              state
              zip
              latitude
              longitude
              fullAddress
              createdAt
            }
            tagList
            latestClosingAt
            createdAt
            updatedAt
          }
        }
    `;

const setUnitClosingDate = `
        mutation SetUnitClosingDate($id: ID!, $latestClosingAt: ISO8601DateTime!) {
          setUnitClosingDate(id: $id, latestClosingAt: $latestClosingAt) {
            id
            latestClosingAt
            createdAt
          }
        }
    `;

const destroyUnit = `
        mutation DestroyUnit($id: ID!) {
          destroyUnit(id: $id) {
            internalIdentifier
            community {
              id
            }
            address {
              fullAddress
            }
            tagList
            createdAt
            updatedAt
          }
        }
    `;

const getUnit = `
        query getUnit($id: ID!) {
          unit(id: $id) {
            id
            internalIdentifier
            community {
                id
                name
            }
            address {
              id
              street1
              street2
              city
              state
              zip
              latitude
              longitude
              fullAddress
              createdAt
            }
            unitResidents {
              id
              resident {
                id
                firstName
                lastName
                fullName
                email
                profileImageUrl
              }
              departedAt
              createdAt
            }
            defaultSubscriberUnitResident {
              id
            }
            currentUnitResidents {
              id
              resident {
                id
                firstName
                lastName
                fullName
                email
                profileImageUrl
              }
              unit {
                id
                internalIdentifier
              }
              stripeCustomerId
              departedAt
              createdAt
            }
            currentUserUnitResident {
                id
                stripeCustomerId
            }
            pendingInvites {
                id
                email
            }
            resources {
              id
              createdBy {
                id
                firstName
                lastName
                fullName
                email
                profileImageUrl
              }
              name
              residentViewable
              description
              displayOnTimeline
              resourceFileName
              resourceFileUrl
              createdAt
            }
            posts {
              id
              title
              createdAt
            }
            unitFees {
              id
              fee {
                id
              }
            }
            fees {
              id
              name
              priceCents
              recurring
              createdAt
            }
            monthlyFees {
              id
              name
              priceCents
              recurring
              createdAt
            }
            quarterlyFees {
              id
              name
              priceCents
              recurring
              createdAt
            }
            pastDueInvoices
            openUnitResidentInvoicesCount
            activeSubscriptions {
              id
              status
              subscriber {
                resident {
                  id
                  firstName
                  lastName
                  email
                }
              }
              autopayAt
              canceledAt
              createdAt
              updatedAt
            }
            activeMonthlySubscriptions {
              id
              status
              subscriber {
                resident {
                  id
                  firstName
                  lastName
                  email
                }
              }
              autopayAt
              canceledAt
              createdAt
              updatedAt
            }
            activeQuarterlySubscriptions {
              id
              status
              subscriber {
                resident {
                  id
                  firstName
                  lastName
                  email
                }
              }
              autopayAt
              canceledAt
              createdAt
              updatedAt
            }
            tagList
            latestClosingAt
            createdAt
            updatedAt
          }
        }
    `;

const getUnitUnitFees = `
        query getUnit($id: ID!) {
          unit(id: $id) {
            id
            unitFees {
              id
              fee {
                id
              }
            }
          }
        }
    `;

const getUnitSubscriptions = `
        query GetUnitSubscriptions($id: ID!) {
          unit(id: $id) {
            id
            subscriptions {
              id
              status
              subscriber {
                resident {
                  id
                  firstName
                  lastName
                  email
                }
              }
              stripeSubscriptionId
              autopayAt
              canceledAt
              createdAt
              updatedAt
            }
            activeSubscriptions {
              id
              status
              subscriber {
                resident {
                  id
                  firstName
                  lastName
                  email
                }
              }
              stripeSubscriptionId
              autopayAt
              canceledAt
              createdAt
              updatedAt
            }
          }
        }
    `;

const getUnitResidentsInvites = `
        query GetUnitResidentsInvites($id: ID!) {
          unit(id: $id) {
            id
            currentUnitResidents {
              id
              resident {
                id
                firstName
                lastName
                email
                profileImageUrl
              }
            }
            pendingInvites {
              id
              email
              sendCount
            }
          }
        }
    `;

const getUnitCurrentUnitResidents = `
        query GetUnitCurrentUnitResidents($id: ID!) {
          unit(id: $id) {
            id
            currentUnitResidents {
              id
              resident {
                id
                firstName
                lastName
                email
                profileImageUrl
              }
              stripeCustomerId
            }
          }
        }
    `;

const getUnitCurrentUserUnitResident = `
        query GetUnitCurrentUserUnitResident($id: ID!) {
          unit(id: $id) {
            id
            currentUserUnitResident {
                id
                stripeCustomerId
            }
          }
        }
    `;

const getUnitWithAccessUpdate = `
        query getUnitWithAccessUpdate($id: ID!) {
          unitWithAccessUpdate(id: $id) {
            id
            internalIdentifier
            community {
                id
                name
            }
            address {
              id
              street1
              street2
              city
              state
              zip
              latitude
              longitude
              fullAddress
              createdAt
            }
            unitResidents {
              id
              resident {
                id
                firstName
                lastName
                fullName
                email
                profileImageUrl
              }
              departedAt
              createdAt
            }
            currentUnitResidents {
              id
              resident {
                id
                firstName
                lastName
                fullName
                email
                profileImageUrl
              }
              departedAt
              createdAt
            }
            currentUserUnitResident {
                id
                stripeCustomerId
            }
            pendingInvites {
                id
                email
            }
            resources {
              id
              createdBy {
                id
                firstName
                lastName
                fullName
                email
                profileImageUrl
              }
              residentViewable
              description
              displayOnTimeline
              resourceFileName
              resourceFileUrl
              createdAt
            }
            fees {
              id
              name
              priceCents
              recurring
              createdAt
            }
            monthlyFees {
              id
              name
              priceCents
              recurring
              createdAt
            }
            quarterlyFees {
              id
              name
              priceCents
              recurring
              createdAt
            }
            activeSubscriptions {
              id
              status
              subscriber {
                resident {
                  id
                  firstName
                  lastName
                  email
                }
              }
              autopayAt
              canceledAt
              createdAt
              updatedAt
            }
            activeMonthlySubscriptions {
              id
              status
              subscriber {
                resident {
                  id
                  firstName
                  lastName
                  email
                }
              }
              autopayAt
              canceledAt
              createdAt
              updatedAt
            }
            activeQuarterlySubscriptions {
              id
              status
              subscriber {
                resident {
                  id
                  firstName
                  lastName
                  email
                }
              }
              autopayAt
              canceledAt
              createdAt
              updatedAt
            }
            tagList
            latestClosingAt
            createdAt
            updatedAt
          }
        }
    `;

const getUnits = `
        query getUnits($communityId: ID!, $vacant: Boolean, $pastDueInvoices: Boolean, $pendingInvites: Boolean, $openUnitResidentInvoices: Boolean, $searchTerm: String, $first: Int, $after: String) {
            units(communityId: $communityId, vacant: $vacant, pastDueInvoices: $pastDueInvoices, pendingInvites: $pendingInvites, openUnitResidentInvoices: $openUnitResidentInvoices, searchTerm: $searchTerm, first: $first, after: $after) {
                edges {
                  node {
                    id
                    internalIdentifier
                    community {
                        id
                        name
                    }
                    address {
                      id
                      street1
                      street2
                      city
                      state
                      zip
                      latitude
                      longitude
                      fullAddress
                      createdAt
                    }
                    unitResidents {
                      id
                      resident {
                        id
                        firstName
                        lastName
                        fullName
                        email
                        profileImageUrl
                      }
                      departedAt
                      createdAt
                    }
                    tagList
                    latestClosingAt
                    pastDueInvoices
                    pendingInvites {
                      id
                    }
                    openUnitResidentInvoicesCount
                    currentUnitResidentsCount
                    createdAt
                    updatedAt
                  }
                }
                totalCount
                pageInfo {
                  startCursor
                  endCursor
                }
            }
        }
    `;

const getUnitsForMap = `
        query getUnits($communityId: ID!) {
            units(communityId: $communityId) {
                edges {
                  node {
                    id
                    internalIdentifier
                    address {
                      id
                      street1
                      street2
                      city
                      state
                      zip
                      latitude
                      longitude
                      fullAddress
                      createdAt
                    }
                    latestClosingAt
                    pastDueInvoices
                    openUnitResidentInvoicesCount
                    currentUnitResidentsCount
                    createdAt
                    updatedAt
                  }
                }
                totalCount
                pageInfo {
                  startCursor
                  endCursor
                }
            }
        }
    `;

const getUnitsSimple = `
        query getUnits($communityId: ID!, $first: Int, $after: String) {
            units(communityId: $communityId, first: $first, after: $after) {
                edges {
                  node {
                    id
                    internalIdentifier
                    createdAt
                  }
                }
                totalCount
                pageInfo {
                  startCursor
                  endCursor
                }
            }
        }
    `;

const getUnitWithCommunity = `
        query getUnitWithCommunity($id: ID!) {
          unit(id: $id) {
            id
            internalIdentifier
            community {
                id
                name
            }
            address {
              id
              street1
              street2
              city
              state
              zip
              latitude
              longitude
              fullAddress
              createdAt
            }
            currentUnitResidents {
              id
              resident {
                id
                firstName
                lastName
                fullName
                email
                profileImageUrl
              }
              departedAt
              createdAt
            }
            currentUserUnitResident {
                id
                stripeCustomerId
            }
            fees {
              id
              name
              priceCents
              recurring
              createdAt
            }
            monthlyFees {
              id
              name
              priceCents
              recurring
              createdAt
            }
            quarterlyFees {
              id
              name
              priceCents
              recurring
              createdAt
            }
            activeSubscriptions {
              id
              status
              subscribable {
                ... on Unit {
                  id
                }
              }
              subscriber {
                resident {
                  id
                  firstName
                  lastName
                  email
                }
              }
              autopayAt
              canceledAt
              createdAt
              updatedAt
            }
            activeMonthlySubscriptions {
              id
              status
              interval
              subscriber {
                resident {
                  id
                  firstName
                  lastName
                  email
                }
              }
              autopayAt
              canceledAt
              createdAt
              updatedAt
            }
            activeQuarterlySubscriptions {
              id
              status
              interval
              subscriber {
                resident {
                  id
                  firstName
                  lastName
                  email
                }
              }
              autopayAt
              canceledAt
              createdAt
              updatedAt
            }
            tagList
            latestClosingAt
            estimatedMonthlyCardServiceFeeAmountCents
            estimatedMonthlyBankAccountServiceFeeAmountCents
            estimatedQuarterlyCardServiceFeeAmountCents
            estimatedQuarterlyBankAccountServiceFeeAmountCents
            createdAt
            updatedAt
            community {
                id
                name
                website
                contactEmail
                contactPhoneNumber
                address {
                  id
                  street1
                  street2
                  city
                  state
                  zip
                  latitude
                  longitude
                  fullAddress
                  createdAt
                }
                token
                profileImageUrl
                establishedYear
                twitterHandle
                facebookHandle
                instagramHandle
                createdAt
            }
          }
        }
    `;

const getUnitWithCommunityAndAccessUpdate = `
        query getUnitWithCommunityAndAccessUpdate($id: ID!) {
          unitWithAccessUpdate(id: $id) {
            id
            internalIdentifier
            community {
                id
                name
            }
            address {
              id
              street1
              street2
              city
              state
              zip
              latitude
              longitude
              fullAddress
              createdAt
            }
            currentUnitResidents {
              id
              resident {
                id
                firstName
                lastName
                fullName
                email
                profileImageUrl
              }
              departedAt
              createdAt
            }
            currentUserUnitResident {
                id
                stripeCustomerId
            }
            fees {
              id
              name
              priceCents
              recurring
              createdAt
            }
            monthlyFees {
              id
              name
              priceCents
              recurring
              createdAt
            }
            quarterlyFees {
              id
              name
              priceCents
              recurring
              createdAt
            }
            activeSubscriptions {
              id
              status
              subscribable {
                ... on Unit {
                  id
                }
              }
              subscriber {
                resident {
                  id
                  firstName
                  lastName
                  email
                }
              }
              autopayAt
              canceledAt
              createdAt
              updatedAt
            }
            activeMonthlySubscriptions {
              id
              status
              interval
              subscriber {
                resident {
                  id
                  firstName
                  lastName
                  email
                }
              }
              autopayAt
              canceledAt
              createdAt
              updatedAt
            }
            activeQuarterlySubscriptions {
              id
              status
              interval
              subscriber {
                resident {
                  id
                  firstName
                  lastName
                  email
                }
              }
              autopayAt
              canceledAt
              createdAt
              updatedAt
            }
            tagList
            latestClosingAt
            estimatedMonthlyCardServiceFeeAmountCents
            estimatedMonthlyBankAccountServiceFeeAmountCents
            estimatedQuarterlyCardServiceFeeAmountCents
            estimatedQuarterlyBankAccountServiceFeeAmountCents
            createdAt
            updatedAt
            community {
                id
                name
                website
                contactEmail
                contactPhoneNumber
                address {
                  id
                  street1
                  street2
                  city
                  state
                  zip
                  latitude
                  longitude
                  fullAddress
                  createdAt
                }
                token
                profileImageUrl
                establishedYear
                twitterHandle
                facebookHandle
                instagramHandle
                createdAt
            }
          }
        }
    `;

export default {
    createUnit,
    updateUnit,
    setUnitClosingDate,
    destroyUnit,
    getUnit,
    getUnitUnitFees,
    getUnitSubscriptions,
    getUnitResidentsInvites,
    getUnitCurrentUnitResidents,
    getUnitCurrentUserUnitResident,
    getUnitWithAccessUpdate,
    getUnits,
    getUnitsForMap,
    getUnitsSimple,
    getUnitWithCommunity,
    getUnitWithCommunityAndAccessUpdate
};