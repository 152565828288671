import React from "react";
import moment from "moment";

const standardDateTime = (timestamp) => {
    return moment(new Date(timestamp)).format("lll");
};

const simpleDateOnly = (timestamp) => {
    return moment(new Date(timestamp)).format("L");
};

export default {
    standardDateTime,
    simpleDateOnly
};