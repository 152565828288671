const getInvite = `
        query GetInvite($token: String!) {
          invite(token: $token) {
            id
            email
            sender {
              id
              email
            }
            recipient {
              id
              email
            }
            invitable {
              ... on Community {
                id
                name
              }
              ... on Unit {
                id
                internalIdentifier
              }
            }
            token
            accepted_at
            createdAt
          }
        }
    `;

const createInvite = `
        mutation CreateInvite($email: String!, $invitableType: String!, $invitableId: ID!) {
          createInvite(email: $email, invitableType: $invitableType, invitableId: $invitableId) {
            id
            email
            sender {
              id
              email
            }
            recipient {
              id
              email
            }
            invitable {
              ... on Community {
                id
                name
              }
              ... on Unit {
                id
                internalIdentifier
              }
            }
            token
            createdAt
          }
        }
    `;

const resendInvite = `
        mutation ResendInvite($id: ID!) {
          resendInvite(id: $id) {
            id
          }
        }
    `;

const destroyInvite = `
        mutation DestroyInvite($id: ID!) {
          destroyInvite(id: $id) {
            id
          }
        }
    `;

export default {
    getInvite,
    createInvite,
    resendInvite,
    destroyInvite
};