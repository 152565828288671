const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

const allowOnlyNumeric = (string) => {
    return string.replace(/[^0-9]/g, "");
}

export default {
    capitalizeFirstLetter,
    allowOnlyNumeric
}