const createFeedback = `
        mutation CreateFeedback($body: String!, $feedbackableType: String!, $feedbackableId: ID!, $client: String) {
          createFeedback(body: $body, feedbackableType: $feedbackableType, feedbackableId: $feedbackableId, client: $client) {
            id
            body
            feedbackable {
              ... on UnitResident {
                __typename
                id
              }
              ... on CommunityManager {
                __typename
                id
              }
            }
            createdAt
          }
        }
    `;

export default {
    createFeedback
};