const createPostReply = `
        mutation CreatePostReply($postId: ID!, $body: String!, $replyableType: String!, $replyableId: ID!, $hidePoster: Boolean) {
          createPostReply(postId: $postId, body: $body, replyableType: $replyableType, replyableId: $replyableId, hidePoster: $hidePoster) {
            id
            post {
              id
            }
            replyable {
                ... on UnitResident {
                  __typename
                  id
                  resident {
                    id
                    firstName
                    lastName
                    email
                    profileImageUrl
                  }
                }
                ... on CommunityManager {
                  __typename
                  id
                  manager {
                    id
                    firstName
                    lastName
                    email
                  }
                }
              }
            body
            createdAt
          }
        }
    `;

export default {
    createPostReply
};