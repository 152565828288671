import React from "react";
import 'intl';
import 'intl/locale-data/jsonp/en';

const standardMoneyUSD = (amountCents) => {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(amountCents / 100)
};

export default {
    standardMoneyUSD
};