import React, { createContext, useReducer, useMemo } from 'react';
import PropTypes from 'prop-types';

const initialState = {};

const initialContext = [{ ...initialState }, () => {}];

export const CommunityContext = createContext(initialContext);

const reducer = (state, action) => {
    switch (action.type) {
        case "set":
            return action.payload;
        case "reset":
            return initialState;
        default:
            return;
    }
};

export function CommunityController(props) {
    const [communityState, updateCommunityState] = useReducer(reducer, initialState);
    const value = useMemo(() => [communityState, updateCommunityState], [communityState]);

    return (<CommunityContext.Provider value={value}>
        {props.children}
    </CommunityContext.Provider>);
}

CommunityController.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired
};